import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import SvgIcon from "@mui/material/SvgIcon";
import Tooltip from "@mui/material/Tooltip";
import React, { useContext, useState } from "react";
import { InfoCircle } from "react-bootstrap-icons";
import "../../../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import { FormContext } from "../context/FormProvider";
import * as Messages from "../messages/messages";
import "./styles/radio-field.css";
import "./styles/radio-group-field.css";

/**
 * RadioField Component
 * @description A component that renders a mui radio input made specifically for individual radio buttons.
 * @param {Object} props - The component props.
 * @param {string} props.label - The label for the radio field.
 * @param {string} props.idProp - The id of the radio field.
 * @param {Object} props.state - The state object.
 * @param {Function} props.setState - The state setter function.
 * @param {boolean} props.val - The value of the radio field.
 * @param {boolean} props.req - A boolean indicating if the field is required.
 * @param {Object} props.debouncedSave - The debounced save function.
 * @param {Function} props.setIsLoading - The loading state setter function.
 * @param {boolean} props.disabledInput - A boolean indicating if the input is disabled.
 * @returns {JSX.Element} The rendered RadioField component.
 */
const RadioField = ({
  label,
  idProp,
  state,
  setState,
  val,
  req,
  debouncedSave,
  setIsLoading,
  disabledInput,
}) => {
  const [open, setOpen] = useState(false);
  const { handleInputDisable } = useContext(FormContext);
  const { userId } = useContext(DashboardContext);

  // Function to handle the radio change
  const handleRadioChange = (e) => {
    let realID = e.target.id.split("-")[0];
    let newState = {};

    if (e.target.checked === true && realID === "sign3x3") {
      newState = {
        ...state,
        [realID]: true,
        signStand: false,
      };
      setState(newState);
    } else if (e.target.checked === true && realID === "signStand") {
      newState = {
        ...state,
        [realID]: true,
        sign3x3: false,
      };
      setState(newState);
    } else if (e.target.checked === true && realID === "t1") {
      newState = {
        ...state,
        [realID]: true,
        t2: false,
        t3: false,
        t4: false,
        t5: false,
        t6: false,
        t7: false,
        t8: false,
        t9: false,
        t10: false,
        t11: false,
        t12: false,
        t13: false,
        other: false,
        otherText: "",
      };
      setState(newState);
    } else if (e.target.checked === true && realID === "t2") {
      newState = {
        ...state,
        [realID]: true,
        t1: false,
        t3: false,
        t4: false,
        t5: false,
        t6: false,
        t7: false,
        t8: false,
        t9: false,
        t10: false,
        t11: false,
        t12: false,
        t13: false,
        other: false,
        otherText: "",
      };
      setState(newState);
    } else if (e.target.checked === true && realID === "t3") {
      newState = {
        ...state,
        [realID]: true,
        t1: false,
        t2: false,
        t4: false,
        t5: false,
        t6: false,
        t7: false,
        t8: false,
        t9: false,
        t10: false,
        t11: false,
        t12: false,
        t13: false,
        other: false,
        otherText: "",
      };
      setState(newState);
    } else if (e.target.checked === true && realID === "t4") {
      newState = {
        ...state,
        [realID]: true,
        t1: false,
        t2: false,
        t3: false,
        t5: false,
        t6: false,
        t7: false,
        t8: false,
        t9: false,
        t10: false,
        t11: false,
        t12: false,
        t13: false,
        other: false,
        otherText: "",
      };
      setState(newState);
    } else if (e.target.checked === true && realID === "t5") {
      newState = {
        ...state,
        [realID]: true,
        t1: false,
        t2: false,
        t3: false,
        t4: false,
        t6: false,
        t7: false,
        t8: false,
        t9: false,
        t10: false,
        t11: false,
        t12: false,
        t13: false,
        other: false,
        otherText: "",
      };
      setState(newState);
    } else if (e.target.checked === true && realID === "t6") {
      newState = {
        ...state,
        [realID]: true,
        t1: false,
        t2: false,
        t3: false,
        t4: false,
        t5: false,
        t7: false,
        t8: false,
        t9: false,
        t10: false,
        t11: false,
        t12: false,
        t13: false,
        other: false,
        otherText: "",
      };
      setState(newState);
    } else if (e.target.checked === true && realID === "t7") {
      newState = {
        ...state,
        [realID]: true,
        t1: false,
        t2: false,
        t3: false,
        t4: false,
        t5: false,
        t6: false,
        t8: false,
        t9: false,
        t10: false,
        t11: false,
        t12: false,
        t13: false,
        other: false,
        otherText: "",
      };
      setState(newState);
    } else if (e.target.checked === true && realID === "t8") {
      newState = {
        ...state,
        [realID]: true,
        t1: false,
        t2: false,
        t3: false,
        t4: false,
        t5: false,
        t6: false,
        t7: false,
        t9: false,
        t10: false,
        t11: false,
        t12: false,
        t13: false,
        other: false,
        otherText: "",
      };
      setState(newState);
    } else if (e.target.checked === true && realID === "t9") {
      newState = {
        ...state,
        [realID]: true,
        t1: false,
        t2: false,
        t3: false,
        t4: false,
        t5: false,
        t6: false,
        t7: false,
        t8: false,
        t10: false,
        t11: false,
        t12: false,
        t13: false,
        other: false,
        otherText: "",
      };
      setState(newState);
    } else if (e.target.checked === true && realID === "t10") {
      newState = {
        ...state,
        [realID]: true,
        t1: false,
        t2: false,
        t3: false,
        t4: false,
        t5: false,
        t6: false,
        t7: false,
        t8: false,
        t9: false,
        t11: false,
        t12: false,
        t13: false,
        other: false,
        otherText: "",
      };
      setState(newState);
    } else if (e.target.checked === true && realID === "t11") {
      newState = {
        ...state,
        [realID]: true,
        t1: false,
        t2: false,
        t3: false,
        t4: false,
        t5: false,
        t6: false,
        t7: false,
        t8: false,
        t9: false,
        t10: false,
        t12: false,
        t13: false,
        other: false,
        otherText: "",
      };
      setState(newState);
    } else if (e.target.checked === true && realID === "t12") {
      newState = {
        ...state,
        [realID]: true,
        t1: false,
        t2: false,
        t3: false,
        t4: false,
        t5: false,
        t6: false,
        t7: false,
        t8: false,
        t9: false,
        t10: false,
        t11: false,
        t13: false,
        other: false,
        otherText: "",
      };
      setState(newState);
    } else if (e.target.checked === true && realID === "t13") {
      newState = {
        ...state,
        [realID]: true,
        t1: false,
        t2: false,
        t3: false,
        t4: false,
        t5: false,
        t6: false,
        t7: false,
        t8: false,
        t9: false,
        t10: false,
        t11: false,
        t12: false,
        other: false,
        otherText: "",
      };
      setState(newState);
    } else if (e.target.checked === true && realID === "other") {
      newState = {
        ...state,
        [realID]: true,
        t1: false,
        t2: false,
        t3: false,
        t4: false,
        t5: false,
        t6: false,
        t7: false,
        t8: false,
        t9: false,
        t10: false,
        t11: false,
        t12: false,
        t13: false,
      };
      setState(newState);
    }

    if (debouncedSave && setIsLoading) {
      setIsLoading(true);
      debouncedSave.mutate(newState);
    }
  };

  return (
    <div className="cb-sign-container" id={`${idProp}-sign-container`}>
      <FormControlLabel
        onChange={handleRadioChange}
        control={
          <Radio
            id={`${idProp}-true`}
            className={`${idProp} ${req ? "required" : ""}`}
            value={true}
            checked={val === true}
            disabled={handleInputDisable(idProp, state, disabledInput, userId)}
            classes={{
              root: "radio-btn-true",
              checked: "radio-btn-checked",
            }}
            inputProps={{ className: `${idProp}-radio` }}
            icon={
              <SvgIcon
                component={CheckBoxOutlineBlankIcon}
                className="radio-btn-icon-true"
                viewBox="3 3 18 18"
              />
            }
            checkedIcon={
              <SvgIcon
                component={CheckBoxIcon}
                className="radio-btn-icon-true"
                viewBox="3 3 18 18"
              />
            }
          />
        }
        label={`${label}:`}
        id={`${idProp}-label-group`}
        classes={{
          label: "radio-btn-label-sign",
          root: "radio-form-control-label-sign",
        }}
        labelPlacement="start"
      />
      <Tooltip
        id="rf-tooltip"
        classes={{
          tooltip: "radio-field-tooltip",
          popper: "radio-field-popper",
          arrow: "radio-field-arrow",
          tooltipPlacementRight: "radio-field-right",
          tooltipPlacementBottom: "radio-field-bottom",
        }}
        title={Messages[`${idProp}Message`] || ""}
        open={open}
        arrow
        placement={
          idProp === "sign3x3" || idProp === "signStand"
            ? "bottom-end"
            : idProp === "t8" ||
              idProp === "t9" ||
              idProp === "t10" ||
              idProp === "t11" ||
              idProp === "t12" ||
              idProp === "t13" ||
              idProp === "other"
            ? "top"
            : "right"
        }
      >
        <InfoCircle
          className="radio-info-circ"
          id={`info-${idProp}`}
          color="white"
          size={30}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        />
      </Tooltip>
    </div>
  );
};

export default RadioField;
