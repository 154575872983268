import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Tooltip from "@mui/material/Tooltip";
import React, { useContext, useEffect, useRef, useState } from "react";
import { InfoCircle } from "react-bootstrap-icons";
import "../../../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import { FormContext } from "../context/FormProvider.jsx";
import { handleKeyDown, handleShowTitlePlaceholder } from "../main-helper.js";
import * as Messages from "../messages/messages.js";
import "../styles/form-container.css";
import "./styles/text-field.css";

/**
 * TitleField Component
 * @description A component that renders a mui outlined input made for employee titles.
 * @param {Object} props - The component props.
 * @param {string} props.idProp - The id of the title field.
 * @param {string} props.label - The label for the title field.
 * @param {string} props.val - The value of the title field.
 * @param {Object} props.state - The slide state object.
 * @param {Function} props.setState - The slide state setter function.
 * @param {boolean} props.req - A boolean indicating if the field is required.
 * @param {Object} props.debouncedSave - The debounced save function.
 * @param {Function} props.setIsLoading - The loading state setter function.
 * @param {Array} props.autoData - The array of relevant titles.
 * @param {boolean} props.disabledInput - A boolean indicating if the input is disabled.
 * @param {string} props.disabledInputMessage - The message to display when the input is disabled.
 * @param {Object} props.invalidData - The invalid data object.
 * @returns {JSX.Element} The rendered TitleField component.
 */
const TitleField = ({
  idProp,
  label,
  val,
  state,
  setState,
  req,
  debouncedSave,
  setIsLoading,
  autoData,
  disabledInput,
  disabledInputMessage,
  invalidData,
}) => {
  const textRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openHelper, setOpenHelper] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const [placeholder, setPlaceholder] = useState("");
  const [suggestedTitle, setSuggestedTitle] = useState("");
  const [adjustMargin, setAdjustMargin] = useState(false);
  const { requestStatus, handleInputFocus, handleInputDisable } =
    useContext(FormContext);
  const { clientId, projectId, queryClient, userId } =
    useContext(DashboardContext);

  // Get the current project data
  const formData = queryClient.getQueryData([
    "currentProject",
    clientId,
    projectId,
  ]);

  // Attempts to find a title used before for a specific individual
  useEffect(() => {
    handleShowTitlePlaceholder(
      idProp,
      state,
      formData,
      placeholder,
      setPlaceholder,
      setSuggestedTitle,
      showPlaceholder,
    );
  }, [showPlaceholder, state, idProp, formData]);

  useEffect(() => {
    if (req && openHelper) {
      setAdjustMargin(true);
    } else {
      setAdjustMargin(false);
    }
  }, [req, openHelper]);

  const handleTitleTextChange = (e) => {
    let newState;

    let value = e.target.value.replace(/[^a-zA-Z0-9- ,'.& #]/g, "");
    newState = { ...state, [idProp]: value };
    setState(newState);

    if (
      placeholder === "" ||
      !placeholder.toLowerCase().startsWith(value.toLowerCase())
    ) {
      if (autoData && autoData.length > 0) {
        let match = autoData.find((title) => {
          if (!title) return false;
          return title.toLowerCase().startsWith(value.toLowerCase());
        });

        if (match) {
          match = value + match.slice(value.length);
        }
        if (match && value !== "") {
          setPlaceholder(match);
          setShowPlaceholder(true);
        } else if (value === "" && suggestedTitle !== "") {
          setPlaceholder(suggestedTitle);
          setShowPlaceholder(true);
        } else {
          setPlaceholder("");
          setShowPlaceholder(false);
        }
      }
    } else if (
      suggestedTitle !== "" &&
      suggestedTitle.toLowerCase().startsWith(value.toLowerCase())
    ) {
      let existingMatch = value + suggestedTitle.slice(value.length);
      setPlaceholder(existingMatch);
      setShowPlaceholder(true);
    }

    if (debouncedSave && setIsLoading) {
      setIsLoading(true);
      debouncedSave.mutate(newState);
    }
  };

  return (
    <div
      className="input-text-container"
      style={
        requestStatus === "RV" &&
        disabledInput === false &&
        invalidData &&
        invalidData["initialValue"] === val &&
        disabledInputMessage === ""
          ? { marginBottom: "15px" }
          : requestStatus === "RV" &&
            disabledInput === false &&
            invalidData &&
            ((invalidData["initialValue"] === val &&
              disabledInputMessage !== "") ||
              val !== "")
          ? { marginBottom: "35px" }
          : requestStatus === "RV" &&
            disabledInput === false &&
            invalidData &&
            val !== invalidData["initialValue"] &&
            val !== ""
          ? { marginBottom: "15px" }
          : adjustMargin && openHelper
          ? { marginBottom: "35px" }
          : { marginBottom: "15px" }
      }
    >
      <label
        id={`${idProp}-label`}
        className={"text-form-label"}
        htmlFor={"text-form"}
      >
        {label}:{" "}
      </label>
      <div className="text-info-container">
        <FormControl
          variant="outlined"
          id="text-form"
          classes={{ root: "form-text-root" }}
          className={`${idProp}-form-control ${
            requestStatus === "RV" &&
            disabledInput === false &&
            invalidData &&
            (val === invalidData["initialValue"] || val === "")
              ? "revise-input"
              : requestStatus === "RV" &&
                disabledInput === false &&
                invalidData &&
                val !== invalidData["initialValue"] &&
                val !== ""
              ? "revised-input"
              : req
              ? "required"
              : ""
          }`}
        >
          <InputLabel
            id="text-field-label"
            htmlFor="text-input"
            classes={{ shrink: "text-field-label-shrink" }}
            className={`${idProp}-text-field-label`}
          >
            {`Add ${label}`}
          </InputLabel>
          <OutlinedInput
            type="text"
            id="text-field"
            className={`${idProp}-input`}
            ref={textRef}
            label={`Add ${label}`}
            value={val}
            onChange={handleTitleTextChange}
            onFocus={() => handleInputFocus(textRef, setOpenHelper)}
            onSelect={() => {
              if (state[`${idProp}`] === "") {
                setOpen(true);
              } else {
                setOpen(false);
              }
              setOpenHelper(true);
              setShowPlaceholder(true);
            }}
            onBlur={() => {
              setOpen(false);
              setShowPlaceholder(false);
              setOpenHelper(false);
              setPlaceholder("");
            }}
            onKeyDown={(e) =>
              handleKeyDown(
                e,
                idProp,
                state,
                setState,
                autoData,
                placeholder,
                debouncedSave,
                setIsLoading,
                setPlaceholder,
                setShowPlaceholder,
                setOpen,
                setOpenHelper,
              )
            }
            disabled={handleInputDisable(idProp, state, disabledInput, userId)}
            autoComplete="off"
            autoCapitalize="words"
            classes={{
              root: "text-field-root",
              notchedOutline: "text-notchedOutline",
              input: "text-field-input",
            }}
            endAdornment={
              <Tooltip
                id="clear-btn-tooltip"
                classes={{
                  tooltip: "tooltip-clear-btn",
                  popper: "popper-clear-btn",
                  tooltipPlacementTop: "tooltip-top",
                }}
                title={val === "" ? "" : "Clear Field"}
                placement="top"
              >
                <InputAdornment
                  className="text-field-clear-adornment"
                  id="text-adornment"
                  position="end"
                  style={
                    val === "" ||
                    handleInputDisable(idProp, state, disabledInput, userId) ===
                      true
                      ? { visibility: "hidden" }
                      : { visibility: "visible" }
                  }
                >
                  <IconButton
                    aria-label="clear input field"
                    onClick={() => {
                      if (debouncedSave) {
                        let newState = { ...state, [idProp]: "" };
                        setState(newState);
                        setIsLoading(true);
                        setPlaceholder("");
                        setShowPlaceholder(false);
                        debouncedSave.mutate(newState);
                      } else {
                        let newState = { ...state, [idProp]: "" };
                        setState(newState);
                        setPlaceholder("");
                        setShowPlaceholder(false);
                      }
                    }}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                    id="clear-icon-button"
                  >
                    <CloseIcon className="close-icon" />
                  </IconButton>
                </InputAdornment>
              </Tooltip>
            }
          />
          {openHelper === true && (
            <FormHelperText
              className={`helper-text ${
                requestStatus === "RV" &&
                disabledInput === false &&
                invalidData &&
                (val === invalidData["initialValue"] || val === "")
                  ? "invalid-input-message"
                  : requestStatus === "RV" &&
                    disabledInput === false &&
                    invalidData &&
                    val !== invalidData["initialValue"] &&
                    val !== ""
                  ? "revised-input-message"
                  : requestStatus === "RV" &&
                    disabledInput === false &&
                    !disabledInputMessage &&
                    invalidData &&
                    val === ""
                  ? "revise-input-message"
                  : ""
              }`}
              classes={{
                root: "text-field-helper",
              }}
            >
              {(requestStatus === "IP" || requestStatus === "I") &&
                placeholder !== "" && (
                  <span className="fill-text-info">
                    Press <strong id="apple-tab">tab</strong> to autofill field
                    with suggested value
                  </span>
                )}
              {requestStatus === "RV" &&
                disabledInput === false &&
                disabledInputMessage &&
                invalidData &&
                val === invalidData["initialValue"] &&
                `Note: ${disabledInputMessage}`}
              {requestStatus === "RV" &&
                disabledInput === false &&
                !disabledInputMessage &&
                invalidData &&
                val === "" &&
                "This field was marked as invalid. Please provide an updated value."}
              {requestStatus === "RV" &&
                disabledInput === false &&
                invalidData &&
                val !== invalidData["initialValue"] &&
                val !== "" &&
                `**Revised: Value changed from ${invalidData["initialValue"]} to ${val}**`}
            </FormHelperText>
          )}
        </FormControl>
        <input
          key={`${idProp}-placeholder`}
          className={`${
            req ? "predictive-placeholder shift" : "predictive-placeholder"
          }`}
          type="text"
          autoComplete="off"
          id="predictive-placeholder"
          value={placeholder}
          disabled={true}
        />
        <Tooltip
          id="tf-tooltip"
          classes={{
            tooltip: "text-field-tooltip",
            popper: "text-field-popper",
            arrow: "text-field-arrow",
          }}
          title={Messages[`${idProp}Message`]}
          placement={"top"}
          arrow
          open={open}
          sx={{
            backgroundColor: "rgb(0, 0, 0, 0.8) !important",
          }}
        >
          <InfoCircle
            className="text-info-circ"
            id={`info-${idProp}`}
            color="white"
            size={30}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
          />
        </Tooltip>
      </div>
    </div>
  );
};
export default TitleField;
