import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import React, { useContext } from "react";
import { InfoCircle } from "react-bootstrap-icons";
import "../../../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import { FormContext } from "../context/FormProvider";
import { sNumMessage } from "../messages/messages";
import "../styles/form-container.css";
import "./styles/text-field.css";

// SliderField component for number of SWPPP copies
/**
 * SlideField Component
 * @description A component that renders a mui slider input made for selecting the number of SWPPP copies.
 * @param {Object} props - The component props.
 * @param {string} props.idProp - The id of the slider field.
 * @param {string} props.label - The label for the slider field.
 * @param {string} props.val - The value of the slider field (0-2).
 * @param {Object} props.state - The slide state object.
 * @param {Function} props.setState - The slide state setter function.
 * @param {boolean} props.req - A boolean indicating if the field is required.
 * @param {Object} props.debouncedSave - The debounced save function.
 * @param {Function} props.setIsLoading - The loading state setter function.
 * @param {boolean} props.disabledInput - A boolean indicating if the input is disabled.
 * @param {Object} props.invalidData - The invalid data object.
 * @param {string} props.disabledInputMessage - The message to display when the input is disabled.
 * @returns {JSX.Element} The rendered SlideField component.
 */
const SliderField = ({
  idProp,
  label,
  val,
  state,
  setState,
  req,
  debouncedSave,
  setIsLoading,
  disabledInput,
  invalidData,
  disabledInputMessage,
}) => {
  const { requestStatus, handleInputDisable } = useContext(FormContext);
  const { userId } = useContext(DashboardContext);

  // Function to handle the slider change
  const handleSliderChange = (e) => {
    let value = e.target.value;
    let newState = { ...state, sNum: value };
    setState(newState);
    if (debouncedSave && setIsLoading) {
      setIsLoading(true);
      debouncedSave.mutate(newState);
    }
  };

  return (
    <div className="input-slider-container">
      <label
        id={`${idProp}-label`}
        className={"text-form-label"}
        htmlFor={"text-form"}
      >
        {label}:{" "}
      </label>
      <div className="slider-info-container">
        <div className="slider-helper-text-container">
          <Slider
            id="sNum-slider"
            className={
              requestStatus === "RV" &&
              disabledInput === false &&
              invalidData &&
              invalidData.initialValue === val
                ? "invalid-slider"
                : requestStatus === "RV" &&
                  disabledInput === false &&
                  invalidData &&
                  invalidData.initialValue !== val
                ? "revised-slider"
                : ""
            }
            step={1}
            min={1}
            max={3}
            track={false}
            value={+val}
            valueLabelDisplay="auto"
            onChange={handleSliderChange}
            marks={[
              { value: 1, label: "1 Copy" },
              { value: 2, label: "2 Copies" },
              { value: 3, label: "3 Copies" },
            ]}
            disabled={handleInputDisable(idProp, state, disabledInput, userId)}
            classes={{
              root: "sliderRoot",
              rail: "sliderRail",
              mark: "sliderMark",
              markLabel: "sliderMarkLabel",
              markLabelActive: "sliderMarkLabelActive",
              thumb: "sliderThumb",
            }}
          />
          <div
            className="slider-helper-text"
            style={
              requestStatus !== "RV" ||
              (requestStatus === "RV" &&
                disabledInput === false &&
                invalidData &&
                invalidData.initialValue === val &&
                !disabledInputMessage)
                ? { visibility: "hidden", height: "22px" }
                : { visability: "visible" }
            }
          >
            <p
              className={
                requestStatus === "RV" &&
                disabledInput === false &&
                invalidData &&
                invalidData.initialValue === val
                  ? "invalid-slider-helper-text"
                  : requestStatus === "RV" &&
                    disabledInput === false &&
                    invalidData &&
                    invalidData.initialValue !== val
                  ? "revised-slider-helper-text"
                  : ""
              }
            >
              {requestStatus === "RV" &&
              disabledInput === false &&
              invalidData &&
              invalidData.initialValue === val &&
              disabledInputMessage
                ? `Note from Argus: ${disabledInputMessage}`
                : requestStatus === "RV" &&
                  disabledInput === false &&
                  invalidData &&
                  invalidData.initialValue !== val &&
                  disabledInputMessage
                ? `Value changed from ${invalidData.initialValue} Cop${
                    invalidData.initialValue !== "1" ? "ies" : "y"
                  } to ${val}`
                : ""}
            </p>
          </div>
        </div>
        <Tooltip
          id="s-tooltip"
          classes={{
            tooltip: "slider-tooltip",
            popper: "slider-popper",
            // tooltipPlacementRight: "slider-right",
            arrow: "slider-arrow",
          }}
          title={sNumMessage}
          placement="top"
          arrow
        >
          <InfoCircle
            className="text-info-circ"
            id={`info-${idProp}`}
            color="white"
            size={30}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default SliderField;
