import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import clsx from "clsx";
import dayjs from "dayjs";
import React, { useContext, useEffect, useRef, useState } from "react";
import { InfoCircle } from "react-bootstrap-icons";
import "../../../../Tooltip/styles/tooltip.css";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import { FormContext } from "../context/FormProvider";
import * as Messages from "../messages/messages";
import "./styles/date-field.css";

/**
 * DateField Component
 * @description A component that renders a mui date picker input field.
 * @param {Object} props - The component props.
 * @param {string} props.idProp - The id of the date field.
 * @param {string} props.label - The label for the date field.
 * @param {string} props.val - The value of the date field.
 * @param {Object} props.state - The state object.
 * @param {Function} props.setState - The state setter function.
 * @param {Object} props.debouncedSave - The debounced save function.
 * @param {boolean} props.req - A boolean indicating if the field is required.
 * @param {Function} props.setIsLoading - The loading state setter function.
 * @param {boolean} props.disabledInput - A boolean indicating if the input is disabled.
 * @param {string} props.disabledInputMessage - The message to display when the input is disabled.
 * @param {Object} props.invalidData - The invalid data object.
 * @returns {JSX.Element} The rendered DateField component.
 */
const DateField = ({
  idProp,
  label,
  val,
  state,
  setState,
  debouncedSave,
  req,
  setIsLoading,
  disabledInput,
  disabledInputMessage,
  invalidData,
}) => {
  const dateRef = useRef(null);
  const [currentDate, setCurrentDate] = useState(dayjs(new Date()));
  const [open, setOpen] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const { requestStatus, disableAll, handleInputFocus, handleInputDisable } =
    useContext(FormContext);
  const { userId } = useContext(DashboardContext);

  // Handler for the date change
  const handleDateChange = (newValue) => {
    let newState;
    // Create new Date instance from the date value
    let date = new Date(newValue["$d"]).toISOString().split("T")[0];
    // Deconstruct the year, month, and day from the date
    let [year, month, day] = date.split("-");
    month = month[0] === "0" ? month[1] : month;
    day = day[0] === "0" ? day[1] : day;
    // Format the date to MM-DD-YYYY
    date = `${month}-${day}-${year}`;
    // Update the dateRef value and the state
    dateRef.current.value = date;
    newState = { ...state, [idProp]: date };
    setState(() => newState);

    // Start loading and save the new state to the database
    setIsLoading(true);
    debouncedSave.mutate(newState);
  };

  // Manually adding an id to the date picker divs
  useEffect(() => {
    // Add an id of "date-form" to all of the divs with the classes "MuiFormControl-root date-picker"
    const datePickerElement = document.querySelectorAll(
      ".MuiFormControl-root.date-picker",
    );
    if (datePickerElement && datePickerElement.length > 0) {
      datePickerElement.forEach((el) => {
        el.id = "date-form";
      });
    }
  }, []);

  return (
    <div
      className="input-date-container"
      style={
        requestStatus === "RV" &&
        disabledInput === false &&
        invalidData &&
        invalidData["initialValue"] === val &&
        disabledInputMessage === ""
          ? { marginBottom: "15px" }
          : requestStatus === "RV" &&
            disabledInput === false &&
            invalidData &&
            ((invalidData["initialValue"] === val &&
              disabledInputMessage !== "") ||
              val !== "")
          ? { marginBottom: "35px" }
          : requestStatus === "RV" &&
            disabledInput === false &&
            invalidData &&
            val !== invalidData["initialValue"] &&
            val !== ""
          ? { marginBottom: "15px" }
          : { marginBottom: "15px" }
      }
    >
      <label className="text-form-label" htmlFor={`${idProp}-date`}>
        {label}:{" "}
      </label>
      <div className="date-info-container">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className={`date-picker ${idProp}-input ${
              requestStatus === "RV" &&
              disabledInput === false &&
              invalidData &&
              (val === invalidData["initialValue"] || val === "")
                ? "revise-input"
                : requestStatus === "RV" &&
                  disabledInput === false &&
                  invalidData &&
                  val !== invalidData["initialValue"] &&
                  val !== ""
                ? "revised-input"
                : req
                ? "required"
                : ""
            }`}
            open={openCalendar}
            ref={dateRef}
            value={val ? dayjs(val) : null}
            onChange={handleDateChange}
            label={`Select a ${label}`}
            minDate={
              idProp === "endDate" && state.startDate
                ? dayjs(state.startDate)
                : currentDate
            }
            views={["year", "month", "day"]}
            timezones={["America/Phoenix"]}
            onClose={() => {
              let newDate = dateRef.current.value;
              if (newDate && newDate.split("-").length === 3) {
                setIsLoading(true);
                debouncedSave.mutate({
                  ...state,
                  [idProp]: newDate,
                });
              }
              setOpenCalendar(false);
              setOpen(false);
            }}
            slots={{
              inputAdornment: (slotProps) => {
                return (
                  <Tooltip
                    id="calendar-tooltip"
                    classes={{
                      tooltip: "tooltip-calendar",
                      popper: "popper-calendar",
                      tooltipPlacementTop: "calendar-tooltip-top",
                    }}
                    title={
                      handleInputDisable(
                        idProp,
                        state,
                        disabledInput,
                        userId,
                      ) === true
                        ? ""
                        : "Open Calendar"
                    }
                    placement="top"
                  >
                    <IconButton
                      id="date-input-adornment-btn"
                      onClick={slotProps.children.props.onClick}
                      className="date-input-adornment"
                      sx={{
                        visibility:
                          handleInputDisable(
                            idProp,
                            state,
                            disabledInput,
                            userId,
                          ) === true
                            ? "hidden"
                            : "visible",
                      }}
                    >
                      <InsertInvitationIcon
                        id="date-input-adornment"
                        color="primary"
                      />
                    </IconButton>
                  </Tooltip>
                );
              },
            }}
            slotProps={{
              field: {
                ref: dateRef,
                id: "date-field",
                onFocus: () => handleInputFocus(dateRef, setOpen),
                onBlur: () => setOpen(false),
                onSelect: () => setOpen(true),
              },
              textField: {
                ref: dateRef,
                classes: {
                  formControl: "date-form-control",
                },
                InputProps: {
                  className: "date-outlined",
                  classes: {
                    root: "date-field-root",
                    notchedOutline: "date-notchedOutline",
                  },
                  onFocus: () => handleInputFocus(dateRef, setOpen),
                },
                helperText: requestStatus === "RV" &&
                  disabledInputMessage &&
                  invalidData && (
                    <span
                      className={`date-helper-text ${
                        requestStatus === "RV" &&
                        disabledInput === false &&
                        invalidData &&
                        val !== invalidData["initialValue"] &&
                        val !== ""
                          ? "date-revised-input-message"
                          : requestStatus === "RV" &&
                            disabledInput === false &&
                            invalidData &&
                            (val === invalidData["initialValue"] || val === "")
                          ? "date-invalid-input-message"
                          : requestStatus === "RV" &&
                            disabledInput === false &&
                            !disabledInputMessage &&
                            invalidData &&
                            val === ""
                          ? "date-revise-input-message"
                          : ""
                      }`}
                    >{`Note: ${disabledInputMessage}`}</span>
                  ),
              },
              inputAdornment: {
                id: "date-field-adornment",
              },
              openPickerButton: {
                onClick: () => {
                  setOpenCalendar(!openCalendar);
                  setOpen(true);
                },
                id: "date-input-adornment-btn",
              },
              openPickerIcon: {
                id: "date-input-adornment",
              },
              popper: {
                id: "date-popper",
              },
              desktopPaper: {
                id: "date-desktop-paper",
              },
              layout: {
                className: "date-layout",
                classes: {
                  contentWrapper: "date-content-wrapper",
                },
              },
              calendarHeader: {
                className: "date-calendar-header",
                id: clsx("date-calendar-header"),
                classes: {
                  viewTransitionContainer: "date-view-transition-container",
                },
              },
              switchViewButton: {
                id: "date-switch-view-btn",
              },
              switchViewIcon: {
                id: "date-switch-view-icon",
              },
              day: {
                id: "calendar-day",
              },
              previousIconButton: {
                id: "date-previous-icon-btn",
              },
              leftArrowIcon: {
                id: "date-left-arrow",
              },
              nextIconButton: {
                id: "date-next-icon-btn",
              },
              rightArrowIcon: {
                id: "date-right-arrow",
              },
            }}
            disabled={
              disableAll ||
              requestStatus === "P" ||
              (disabledInput === true && requestStatus === "RV")
                ? true
                : false
            }
          />
        </LocalizationProvider>
        <Tooltip
          id="tf-tooltip"
          classes={{
            tooltip: "text-field-tooltip",
            popper: "text-field-popper",
            arrow: "text-field-arrow",
          }}
          title={Messages[`${idProp}Message`]}
          placement="top"
          arrow
          open={open}
        >
          <InfoCircle
            className="date-info-circ"
            id={`info-${idProp}`}
            color="white"
            size={30}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default DateField;
